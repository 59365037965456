<template>
    <div>
        <div class="header-title justify-content-between">
            <b>{{ $t("Operators") }}</b>
            <div>
                <button v-if="inviteOperator" class="btn btn-solid w-100 px-3" v-b-modal.invite-operator-modal>
                    <span class="mr-3">{{ $t("Invite") }}</span>
                    <inline-svg :src="require('../assets/icon/new-icon/user-add.svg')" width="24"
                        height="24"></inline-svg>
                </button>
            </div>
        </div>
        <div class="mt-2">
            <div class="sub-title">{{ $t("Operators Permission") }}</div>
            <!-- <div class="d-flex justify-content-center align-items-center h-100" v-if="isLoading">
                <b-spinner variant="success" label="Spinning"></b-spinner>
            </div> -->
            <div>
                <b-table id="operators-table" :busy="isLoading" class="text-center" fixed responsive
                    :fields="operatorsField" :items="operators" tbody-tr-class="operators-rowClass"
                    @row-clicked="handleRowClicked" empty-filtered-text="No Data" show-empty>

                    <template #table-colgroup="scope">
                        <col v-for="field in scope.fields" :key="field.key"
                            :style="{ width: field.key == '-' ? '50px' : '150px' }">
                    </template>

                    <template #table-busy>
                        <div class="text-center text-success my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <!-- <strong >Loading...</strong> -->
                        </div>
                    </template>

                    <template v-slot:head(-)>
                        <div></div>
                    </template>

                    <template #cell(email)="data">
                        {{ data.item.email }}
                    </template>
                    <template #cell(access)="data">
                        <b-icon :icon="indicatorList[data.item.status == 'active' ? true : false]"></b-icon>
                    </template>
                    <template #cell(setting)="data">
                        <b-icon :icon="indicatorList[data.item.permission.SETTING]"></b-icon>
                    </template>
                    <template #cell(invite)="data">
                        <b-icon :icon="indicatorList[data.item.permission.INVITE]"></b-icon>
                    </template>
                    <template #cell(withdraw)="data">
                        <b-icon :icon="indicatorList[data.item.permission.WITHDRAW]"></b-icon>
                    </template>
                    <template #cell(action)="data">
                        <inline-svg stroke="white" :src="require('../assets/icon/edit.svg')" aria-label="swap" id="edit"
                            @click.stop="showEditOperator(data)"></inline-svg>
                        <!-- <b-button v-if="settingOperator" class="btn btn-common mr-3" @click="showEditOperator(data)">
                            <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap"
                                id="edit"></inline-svg>
                        </b-button> -->
                        <!-- <b-icon v-show="root" icon="chevron-right" @click="roataeIcon(this)"></b-icon> -->
                    </template>

                    <template #cell(-)="data">
                        <b-icon-triangle-fill :rotate="!dropdownIcons[data.index] ? '180' : '0' " scale="0.8"></b-icon-triangle-fill>
                    </template>

                    <!-- start expended body -->
                    <template slot="row-details" slot-scope="row" v-if="root">
                        <div class="d-flex justify-content-center align-items-center h-100" v-if="isListLoading">
                            <b-spinner variant="success" label="Spinning"></b-spinner>
                        </div>
                        <div v-else>
                            <div id="table-dropdown" class="operators-b-table">
                                <div class="border-top title text-left pt-2 pl-3"><b>{{ $t("Withdraw Limit") }}</b>
                                </div>
                                <b-table class="text-left" :fields="operatorsWithdrawLimitField"
                                    :items="operatorWithdrawLimits[row.item.email] ?? []" responsive outlined show-empty
                                    empty-filtered-text="No Data" >
                                    <template #cell(coin)="data">
                                        <img class="mr-2" :src="data.item.crypto.image" alt="coin-symbol-savvix" width="30" height="30">
                                        {{ data.item.crypto.symbol }}
                                        <small>{{ data.item.crypto.network }}</small>
                                    </template>
                                    <template #cell(single_limit)="data">
                                        {{ `$${data.item.single_limit}` ?? "Unset" }}
                                    </template>
                                    <template #cell(hourly_limit)="data">
                                        {{ `$${data.item.hourly_limit}` ?? "Unset" }}
                                    </template>
                                    <template #cell(daily_limit)="data">
                                        {{ `$${data.item.daily_limit}` ?? "Unset" }}
                                    </template>
                                    <template #cell(action)="data">
                                        <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap" stroke="var(--info)"
                                                id="edit" @click="showEditOperatorsWithdrawLimit(data.item)"></inline-svg>
                                        <!-- <b-button class="btn btn-common mr-3"
                                            @click="showEditOperatorsWithdrawLimit(data.item)">
                                            <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap" stroke="red"
                                                id="edit"></inline-svg>
                                        </b-button> -->
                                    </template><br>
                                </b-table>
                            </div>
                        </div>
                    </template>
                    <!-- end expended body -->
                </b-table>
            </div>
        </div>

        <!-- Add Operators Modal -->
        <b-modal id="invite-operator-modal" ref="invite-operator-modal" hide-footer title="Invite Operator">
            <form id="register" @submit.stop.prevent="inviteNewOperator">
                <div class="form-group text-center">
                    <label for="email">Email</label>
                    <b-form-input id="example-input-1" :placeholder="$t(`Enter email`)" name="example-input-1"
                        v-model="$v.inviteForm.email.$model" :state="validateState('email', 'inviteForm')"
                        aria-describedby="input-1-live-feedback" required></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t("This is a required field.")
                    }}</b-form-invalid-feedback>
                </div>
                <div class="mt-5 text-center">
                    <button type="submit" class="activate-btn w-50" v-if="!isSubmitting">{{ $t("Invite") }}</button>
                    <button type="button" class="activate-btn w-50" v-else>
                        <b-spinner small variant="success" label="Spinning"></b-spinner>
                    </button>
                </div>
            </form>
        </b-modal>

        <!-- Edit Operator Modal -->
        <b-modal id="edit-operator-modal" ref="edit-operator-modal" hide-footer title="Edit Permission"
            @hidden="onEditModalHidden" centered>
            <div class="d-flex flex-wrap">
                <div class="col-6 p-2 text-center">
                    <label class="edit-checkbox-container">
                        {{ $t("ACCESS") }}
                        <input v-model="permissionStatus" type="checkbox" :checked="permissionStatus">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div v-for="(permission, key) in localPermissions" :key="`permission${key}`"
                    class="col-6 p-2 text-center">
                    <label class="edit-checkbox-container">
                        {{ key }}
                        <input v-model="localPermissions[key]" type="checkbox" :checked="permission">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

            <div class="mt-5 text-center">
                <button @click="updatePermission" class="activate-btn w-50">{{ $t("Update") }}</button>
            </div>
        </b-modal>


        <!-- Update Threshold Modal -->
        <b-modal ref="edit-withdraw-limit-modal" hide-footer :title="`EDIT ${withdrawLimitForm.symbol} WITHDRAW LIMIT`"
            centered>
            <div class="d-flex flex-column text-center">
                <form id="update-threshold-form" class="modal-form" @submit.stop.prevent="updateWithdrawLimit">
                    <div class="form-group">
                        <label for="input-live">{{ $t("Single Limit") }}</label>
                        <b-form-input id="example-input-1" :placeholder="$t(`Enter single limit`)"
                            v-model="$v.withdrawLimitForm.single_limit.$model"
                            :state="validateState('single_limit', 'withdrawLimitForm')"
                            aria-describedby="input-1-live-feedback" type="number" step="0.0001"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                            $t("Please key in number")
                        }}</b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <label for="input-live">{{ $t("Hourly Limit") }}</label>
                        <b-form-input id="example-input-1" :placeholder="$t(`Enter hour limit`)"
                            v-model="$v.withdrawLimitForm.hourly_limit.$model"
                            :state="validateState('hourly_limit', 'withdrawLimitForm')"
                            aria-describedby="input-1-live-feedback" type="number" step="0.0001"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                            $t("Please key in number")
                        }}</b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <label for="input-live">{{ $t("Daily Limit") }}</label>
                        <b-form-input id="example-input-1" :placeholder="$t(`Enter daily limit`)"
                            v-model="$v.withdrawLimitForm.daily_limit.$model"
                            :state="validateState('daily_limit', 'withdrawLimitForm')"
                            aria-describedby="input-1-live-feedback" type="number" step="0.0001"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                            $t("Please key in number")
                        }}</b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <div class="mt-5 mb-5 w-100">
                            <button type="submit" class="submit-btn">{{ $t("Edit") }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import api from "../helper/api.js";
import { required, email } from "vuelidate/lib/validators";
 

export default {
    name: "Operators",
    components: {
        InlineSvg,
    },
    data() {
        return {
            root: localStorage.getItem('root'),
            operatorsField: [
                'email',
                'access',
                'setting',
                'invite',
                'withdraw',
                'action',
                "-"
            ],
            operatorsWithdrawLimitField: [
                'coin',
                'single_limit',
                'hourly_limit',
                'daily_limit',
                'action'
            ],
            operators: [],
            inviteOperator: (localStorage.getItem('inviteOperator') === 'true'),
            settingOperator: (localStorage.getItem('settingOperator') === 'true'),
            isLoading: true,
            inviteForm: {
                email: ""
            },
            indicatorList: {
                true: 'check-lg',
                false: 'x-lg'
            },
            colorList: {
                true: 'success',
                false: 'danger'
            },
            permissionStatus: true,
            localPermissions: null,
            permissions: null,
            operatorId: null,
            allOpenRows: [],
            operatorWithdrawLimits: null,
            isListLoading: true,
            operatorWithdrawLimitsData: [],
            withdrawLimitForm: {
                id: "",
                api_symbol: "",
                single_limit: "",
                hourly_limit: "",
                daily_limit: "",
                symbol:""
            },
            isSubmitting: false,
            dropdownIcons : []
        }
    },
    validations: {
        inviteForm: {
            email: {
                required,
                email
            },
        },
        withdrawLimitForm: {
            daily_limit: {
                // numeric
            },
            hourly_limit: {
                // numeric
            },
            single_limit: {
                // numeric
            }
        }
    },


    watch: {
        'localPermissions.INVITE': function (val) {
            this.localPermissions.SETTING = val;
        },
        'localPermissions.SETTING': function (val) {
            this.localPermissions.INVITE = val;
        }
    },
    // watch:{
    //     'localPermissions.INVITE':function(val){
    //         this.localPermissions.SETTING = val;
    //     },
    //     'localPermissions.SETTING':function(val){
    //         this.localPermissions.INVITE = val;
    //     }
    // },

    async mounted() {
        await this.getOperators();
    },

    methods: {
        validateState(name, form) {
            const { $dirty, $error } = this.$v[form][name];
            return $dirty ? !$error : null;
        },
        handleRowClicked(item, index) {

            this.dropdownIcons[index] = !this.dropdownIcons[index];

            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                    this.$set(ele, "_showDetails", !ele._showDetails);
                }
            });
            this.allOpenRows = [];
            this.$set(item, "_showDetails", !item._showDetails);
            this.allOpenRows.push(item);

            if(!this.dropdownIcons[index]){
                return;
            }

            this.getOperatorsWithdrawLimit().then(() => {
                this.isListLoading = false;
            });

        },
        async getOperatorsWithdrawLimit() {
            this.isListLoading = true;
            try {
                const response = await api.apiCall('core', 'GET', '/operator-settings');
                console.log(response.message);
                const operatorsList = response.message.reduce((key, item) => {
                    key[item.email] = key[item.email] || [];
                    key[item.email].push(item);
                    return key;
                }, {});

                this.operatorWithdrawLimits = operatorsList;

            } catch (error) {
                console.error(error);
            }
        },
        async getOperators() {
            try {
                const response = await api.apiCall('core', 'GET', '/operators/all');
                this.operators = response.message;
                this.dropdownIcons = response.message.map(item =>
                    item['dropdown'] = false
                );

                console.log(this.dropdownIcons);

                this.isLoading = false;
                // console.log(response);
            } catch (error) {
                console.error(error);
            }
        },
        async inviteNewOperator() {

            let params = {
                email: this.inviteForm.email
            };

            try {
                this.isSubmitting = true;
                const response = await api.apiCall('core', 'POST', '/operators/invite', params);
                console.log(response);
                this.$swal({
                    text: this.$t("Invite Success"),
                    icon: 'success'
                });
                this.isSubmitting = false;
                this.$refs['invite-operator-modal'].hide();

            } catch (error) {
                console.error(error);
                this.$swal({
                    text: error.message,
                    icon: 'error',
                    // confirmButtonColor: '#33AD4A',
                });
                this.isSubmitting = false;
            }
        },

        async updatePermission() {
            let params = {
                status: this.permissionStatus,
                permission: this.localPermissions,
                id: this.operatorId
            };

            try {
                const response = await api.apiCall('core', 'POST', '/operators/editpermission', params);
                console.log(response);
                this.$swal({
                    text: this.$t("Update Success"),
                    icon: 'success'
                });
                this.$refs['edit-operator-modal'].hide();
                this.isLoading = true;
                this.getOperators();

            } catch (error) {
                console.error(error);
                this.$swal({
                    text: error.message,
                    icon: 'error',
                    // confirmButtonColor: '#33AD4A',
                });
            }
        },

        showEditOperator(data) {
            let status = {
                'active': true,
                'suspend': false
            };
            this.permissionStatus = status[data.item.status];
            this.localPermissions = JSON.parse(JSON.stringify(data.item.permission));
            this.operatorId = data.item.id
            this.$refs['edit-operator-modal'].show();
        },

        showEditOperatorsWithdrawLimit(data) {
            console.log('limit',data)
            this.withdrawLimitForm = {
                id: data.id,
                api_symbol: data.crypto.api_symbol,
                single_limit: data.single_limit,
                daily_limit: data.daily_limit,
                hourly_limit: data.hourly_limit,
                symbol : data.crypto.symbol
            }
            this.$refs['edit-withdraw-limit-modal'].show();
        },

        async updateWithdrawLimit() {
            let params = {
                id: this.withdrawLimitForm.id.toString(),
                crypto: this.withdrawLimitForm.api_symbol,
                single_limit: parseFloat(this.withdrawLimitForm.single_limit),
                daily_limit: parseFloat(this.withdrawLimitForm.daily_limit),
                hourly_limit: parseFloat(this.withdrawLimitForm.hourly_limit)
            };

            try {
                await api.apiCall('core', 'POST', '/operator-settings/edit', params);
                this.$swal({
                    text: this.$t("Update Withdraw Limit Success"),
                    icon: 'success'
                });
                this.$refs['edit-withdraw-limit-modal'].hide();
                this.getOperatorsWithdrawLimit().then(() => {
                    this.isListLoading = false;
                });

            } catch (error) {
                console.error(error);
                this.$swal({
                    text: error.message,
                    icon: 'error',
                    // confirmButtonColor: '#33AD4A',
                });
            }
        },

        onEditModalHidden() {

        },
    },
}
</script>

<style>
@import '../css/styles.css';

#operators-table {
    background-color: var(--info);
    color: var(--light);
}

#operators-table.table th,
#operators-table.table td,
#operators-table.table tr {
    vertical-align: middle;
    /* padding: 0.25rem; */
    padding: 10px;
    border: none;
    /* border-top: none; */
    /* border-top-color: unset; */
}

.operators-rowClass:hover,
.operators-rowClass:active {
    cursor: pointer;
    background-color: var(--hover);
}

#operators-table .b-table-details.operators-rowClass {
    background-color: var(--dropdown);
}

.operators-b-table .table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 10px;
}

.operators-b-table thead {
    color: var(--gray);
}

.operators-b-table tr td {
    background-color: var(--light);
}

.operators-b-table tr td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.operators-b-table tr td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.sub-title {
    margin: 10px;
    color: var(--info);
    font-weight: 900;
    font-size: larger;
}

.add-operator-btn {
    border: 1px solid var(--primary);
}

.edit-checkbox-container {
    position: relative;
    width: 50%;
}

.edit-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.edit-checkbox-container .checkmark {
    position: absolute;
    top: 0;
    right: -45px;
    height: 25px;
    width: 25px;
    background-color: var(--gray);
}

/* When the checkbox is checked, add a blue background */
.edit-checkbox-container input:checked~.checkmark {
    background-color: var(--primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.edit-checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.edit-checkbox-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (min-width:1025px) {
    .edit-checkbox-container .checkmark {
        position: absolute;
        top: 0;
        right: -22px;
        height: 25px;
        width: 25px;
        background-color: var(--gray);
    }
}
</style>